@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-size: 14px;
}

html, body {
  font-size: 14px;
}

.fas, .far, .fa-duotone, .fa-sharp {
  display: inline-block;
  min-height: 1px;
  min-width: 13px;
}

.scrollbar-none {
  scrollbar-width: none;
}

.custom-scrollbar::-webkit-scrollbar-track
{
  @apply h-4 bg-transparent;
}

.custom-scrollbar::-webkit-scrollbar
{
  @apply w-1 h-1 bg-transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb
{
  @apply bg-blue-200 border border-blue-300 h-4;
}

.fa-orange-accent {
  --fa-primary-color: theme('colors.blue.700');
  --fa-primary-opacity: 1;
  --fa-secondary-color: theme('colors.orange.800');
  --fa-secondary-opacity: 0.3;
}

@layer base {
  h1 {
    @apply mb-4 text-3xl font-bold;
  }

  h2 {
    @apply mb-3 text-2xl;
  }

  p {
    @apply mb-4 text-base leading-relaxed;
  }
}
